export const EditorialSectionMockData = {
  1: {
    data: {
      id: 1,
      attributes: {
        layout: 'invert_cols',
        bg: 'dark',
        textAlign: 'left',
        openingTitle: 'La ricerca',
        title:
          'L’<span class="Emphasized">impegno</span> costante dei ricercatori e delle ricercatrici AIRC',
        paragraph:
          'Raggiungere la quota dei 10.000€ permetterà a Maria di concludere il suo ciclo di chemioterapia',
        image: {
          url: 'https://picsum.photos/343/333',
          alt: 'Description of the image',
          width: 343,
          height: 333,
        },
        ctaButton: {
          label: 'Sostieni la ricerca',
          url: '/dona',
        },
        createdAt: '2023-10-01T12:34:56.789Z',
        updatedAt: '2023-10-01T12:34:56.789Z',
        publishedAt: '2023-10-01T12:34:56.789Z',
      },
    },
    meta: {},
  },
  2: {
    data: {
      id: 2,
      attributes: {
        layout: null,
        bg: null,
        textAlign: 'left',
        openingTitle: 'I medici',
        title:
          '<span class="Emphasized">Ogni giorno</span> migliaia di medici sono impegnati su tutto il territorio',
        paragraph:
          'Il loro impegno è fondamentale per i pazienti che contano su di loro.',
        image: {
          url: 'https://picsum.photos/343/334',
          alt: 'Description of the image',
          width: 343,
          height: 334,
        },
        ctaButton: {
          label: 'Dona ora',
          url: '/dona',
        },
        testimonialCard: {
          data: {
            id: 1,
            attributes: {
              title: 'Marco',
              subtitle: 'San Raffaele di Milano',
              image: {
                url: 'https://i.pravatar.cc/50',
              },
              text: 'Ogni giorno da circa 20 anni do il meglio per fare in modo che le persone abbiano una possibilità contro il cancro.',
              createdAt: '2023-10-01T12:34:56.789Z',
              updatedAt: '2023-10-01T12:34:56.789Z',
              publishedAt: '2023-10-01T12:34:56.789Z',
            },
          },
        },
        createdAt: '2023-10-01T12:34:56.789Z',
        updatedAt: '2023-10-01T12:34:56.789Z',
        publishedAt: '2023-10-01T12:34:56.789Z',
      },
    },
    meta: {},
  },
}
