export const DonateSectionMockData = {
  1: {
    data: {
      id: 1,
      attributes: {
        layout: null,
        bg: 'rich-blue',
        textAlign: 'left',
        openingTitle: 'il tuo supporto',
        title:
          '<span class="Emphasized">Dona ora</span> il tuo contributo e sostieni la ricerca di AIRC',
        paragraph:
          'Unisciti ad altri +3000 donatori ogni anno e aiutaci a fare il prossimo passo nella scoperta della cura contro il cancro.',
        ctaButton: {
          label: 'Sostieni la ricerca',
          url: '/dona',
        },
        progressCard: {},
        createdAt: '2023-10-01T12:34:56.789Z',
        updatedAt: '2023-10-01T12:34:56.789Z',
        publishedAt: '2023-10-01T12:34:56.789Z',
      },
    },
    meta: {},
  },
  2: {
    data: {
      id: 2,
      attributes: {
        layout: null,
        bg: 'rich-blue',
        textAlign: 'left',
        openingTitle: 'il tuo supporto',
        title:
          '<span class="Emphasized">Contribuisci</span> ora alla prossima scoperta',
        paragraph:
          'Unisciti ad altri +3000 donatori ogni anno e aiutaci a fare il prossimo passo nella scoperta della cura contro il cancro.',
        ctaButton: {
          label: 'Dona adesso',
          url: '/dona',
        },
        progressCard: {},
        createdAt: '2023-10-01T12:34:56.789Z',
        updatedAt: '2023-10-01T12:34:56.789Z',
        publishedAt: '2023-10-01T12:34:56.789Z',
      },
    },
    meta: {},
  },
}
