'use client'

/* ---------------------------------
Homepage
--------------------------------- */

import {
  CampaignSection,
  DonateSection,
  DonorStoriesSection,
  EditorialSection,
  Footer,
  Header,
  HeroSection,
  ProgressSection,
  StoriesSection,
} from 'components/organism'
import { ClientOnly, StickyCTA } from 'components/molecules'
import {
  CampaignSectionMockData,
  DonateSectionMockData,
  DonorStoriesSectionMockData,
  EditorialSectionMockData,
  HeroSectionMockData,
  ProgressSectionMockData,
  StoriesSectionMockData,
} from '@/mocks/Homepage'
import { useRouter } from 'next/navigation'

export default function Homepage() {
  const router = useRouter()

  return (
    <ClientOnly>
      {/* TODO to be moved to layout */}
      <Header />

      {/* HeroSection */}
      <HeroSection data={HeroSectionMockData.data} />

      {/* CampaignSection */}
      <CampaignSection data={CampaignSectionMockData.data} />

      {/* EditorialSection */}
      <EditorialSection data={EditorialSectionMockData['1'].data} />

      {/* StoriesSection */}
      <StoriesSection data={StoriesSectionMockData.data} />

      {/* DonateSection */}
      <DonateSection data={DonateSectionMockData['1'].data} />

      {/* DoctorsSection */}
      <EditorialSection data={EditorialSectionMockData['2'].data} />

      {/* ProgressSection */}
      <ProgressSection data={ProgressSectionMockData.data} />

      {/* DonateSection */}
      <DonateSection data={DonateSectionMockData['2'].data} />

      {/* DonorStoriesSection */}
      <DonorStoriesSection data={DonorStoriesSectionMockData.data} />

      {/* TODO to be moved to layout */}
      <Footer />

      <StickyCTA
        action={() => {
          if (!window) return
          window.location.href = '/dona'
        }}
      />
    </ClientOnly>
  )
}
