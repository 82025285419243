export const StoriesSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: null,
      textAlign: 'center',
      paragraphPosition: 'right',
      openingTitle: 'chi ha bisogno di te',
      title:
        'Le <span class="Emphasized">persone</span> a cui hai dato speranza',
      paragraph:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      // this would be a `slides` data type,
      // which would specify if the content
      // is an image or a video in some way
      slides: [
        {
          type: 'image',
          url: 'https://picsum.photos/343/336',
          alt: 'Description of the fourth image',
          width: 343,
          height: 336,
        },
        {
          type: 'image',
          url: 'https://picsum.photos/343/336',
          alt: 'Description of the fourth image',
          width: 343,
          height: 336,
        },
        {
          type: 'image',
          url: 'https://picsum.photos/343/336',
          alt: 'Description of the fourth image',
          width: 343,
          height: 336,
        },
        {
          type: 'image',
          url: 'https://picsum.photos/343/336',
          alt: 'Description of the fourth image',
          width: 343,
          height: 336,
        },
        {
          type: 'image',
          url: 'https://picsum.photos/343/336',
          alt: 'Description of the fourth image',
          width: 343,
          height: 336,
        },
      ],
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
