export const CampaignSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: null,
      textAlign: 'center',
      paragraphPosition: 'top',
      openingTitle: 'Il tuo contributo',
      title: '<span class="Emphasized">Manca poco</span> per aiutare Maria',
      paragraph:
        'Raggiungere la quota dei 10.000€ permetterà a Maria di concludere il suo ciclo di chemioterapia',
      image: {
        url: 'https://picsum.photos/343/333',
        alt: 'Description of the image',
        width: 343,
        height: 333,
      },
      ctaButton: {
        label: 'Dai il tuo contributo',
        url: '/dona',
      },
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
