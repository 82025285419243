export const ProgressSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: 'dark',
      textAlign: 'center',
      openingTitle: 'a che punto siamo',
      title:
        'Il costante <span class="Emphasized">progresso</span> della ricerca',
      paragraph: 'Ecco come le vostre donazioni contribuiscono alla ricerca.',
      highlightCard: {
        title: 'Proprio ora',
        body: 'I nostri ricercatori e ricercatrici sono impegnati a cercare un modo efficace per prevenire il tumore al seno',
      },
      ctaButton: {
        label: 'Sostieni la ricerca',
        url: '/dona',
      },
      // TODO TextCard…
      image: {
        url: 'https://picsum.photos/343/333',
        alt: 'Description of the image',
        width: 343,
        height: 333,
      },
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
