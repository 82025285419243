export const DonorStoriesSectionMockData = {
  data: {
    id: 1,
    attributes: {
      layout: null,
      bg: null,
      textAlign: 'center',
      openingTitle: 'donatori e donatrici',
      title:
        'Chi ci <span class="Emphasized">sostiene</span> ogni giorno da sempre',
      paragraph:
        'Ascolta le testimonianze di chi contribuisce ogni volta che può.',
      testimonialCardPosition: 'right_col',
      testimonialCard: {
        data: {
          id: 1,
          attributes: {
            title: 'Aura',
            subtitle: 'Impiegata',
            image: {
              url: 'https://i.pravatar.cc/50',
            },
            text: 'Da sempre mi impegno a donare ogni mese parte dei miei risparmi perché voglio contribuire per donare una seconda opportunità.',
            createdAt: '2023-10-01T12:34:56.789Z',
            updatedAt: '2023-10-01T12:34:56.789Z',
            publishedAt: '2023-10-01T12:34:56.789Z',
          },
        },
      },
      ctaButton: {
        label: 'Diventa donatore',
        url: '/dona',
      },
      // this would be a `slides` data type,
      // which would specify if the content
      // is an image or a video in some way
      slides: [
        {
          type: 'video',
          url: 'https://example.com/video1.mp4',
          poster: 'https://picsum.photos/343/333',
          alt: 'Description of the first video',
          width: 343,
          height: 333,
        },
        {
          type: 'video',
          url: 'https://example.com/video1.mp4',
          poster: 'https://picsum.photos/343/333',
          alt: 'Description of the first video',
          width: 343,
          height: 333,
        },
        {
          type: 'video',
          url: 'https://example.com/video2.mp4',
          poster: 'https://picsum.photos/343/335',
          alt: 'Description of the third video',
          width: 343,
          height: 335,
        },
        {
          type: 'video',
          url: 'https://example.com/video1.mp4',
          poster: 'https://picsum.photos/343/333',
          alt: 'Description of the first video',
          width: 343,
          height: 333,
        },
        {
          type: 'video',
          url: 'https://example.com/video3.mp4',
          poster: 'https://picsum.photos/343/337',
          alt: 'Description of the fifth video',
          width: 343,
          height: 337,
        },
      ],
      createdAt: '2023-10-01T12:34:56.789Z',
      updatedAt: '2023-10-01T12:34:56.789Z',
      publishedAt: '2023-10-01T12:34:56.789Z',
    },
  },
  meta: {},
}
